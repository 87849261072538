import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { getSessionStorage } from 'src/app/functions/generics';
import { CognitoAttributes } from 'src/app/models/cognito-attributes/cognito-attributes.model.js';
import { CognitoUser } from 'src/app/models/cognito-user/cognito-user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PIPE_DATE_YEAR_MONTH_DAY_HOURS_MINS_SECONDS } from '../../../utils/const/const';
import { PIPE_DATE_YEAR_MONTH_DAY } from '../../../utils/const/const';

@Component({
  selector: 'app-details-user',
  templateUrl: './details-user.component.html',
  styleUrls: ['./details-user.component.css']
})
export class DetailsUserComponent implements OnInit {

  cognitoUser: CognitoUser;
  formatDateWithHours = PIPE_DATE_YEAR_MONTH_DAY_HOURS_MINS_SECONDS;
  formatDate = PIPE_DATE_YEAR_MONTH_DAY;
  attributesCustom: Array<CognitoAttributes>

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public authService: AuthService) {
  }

  ngOnInit(): void {
    this.getCognitoUserOrReturnMainMenu();
    this.prepareAttributesCustom();
  }

  getCognitoUserOrReturnMainMenu() {
    this.cognitoUser = getSessionStorage("detailsUser");
    if (this.cognitoUser == null) {
      this.router.navigate(['..'], { relativeTo: this.activatedRoute });
    }
  }

  prepareAttributesCustom() {
    this.attributesCustom = new Array<CognitoAttributes>();
    this.cognitoUser.Attributes.forEach(attributes => {
      if (attributes.Name.includes('custom')) {
        this.attributesCustom.push(attributes);
      }
    });
  }

}
