import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  public exportExcel(jsonData: any[], fileName: string): void {

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  public importExcelFile(binaryString: string): XLSX.AOA2SheetOpts {
    const workBook: XLSX.WorkBook = XLSX.read(binaryString, { type: 'binary' });

    const sheetName: string = workBook.SheetNames[0];
    const workSheet: XLSX.WorkSheet = workBook.Sheets[sheetName];

    const data = <XLSX.AOA2SheetOpts>(XLSX.utils.sheet_to_json(workSheet, { header: 1 }));

    return data;
  }
}
