<p-toolbar>
    <div class="p-toolbar-group-left">
        <strong style="margin-left: 0.25em;">{{titulo}}</strong>            
    </div>
    
    <div *ngIf="showBackButton" class="p-toolbar-group-right">
        <em class="pi pi-arrow-circle-left" (click)="backHistory()" 
            style="font-size: 1.5rem; color: black; cursor: pointer;"></em>
    </div>
</p-toolbar>
