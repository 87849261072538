<app-location-bar titulo="{{'MAIN_MENU.MANAGE_USERS' | translate }}" [showBackButton]="false"></app-location-bar>
<div class="container-fluid">
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12 description_main_menu">
            <span>{{'MAIN_MENU.DESCRIPTION' | translate}}</span>
        </div>
    </div>
    <div class="p-grid p-fluid p-justify-center p-mt-5">
        <div class="p-lg-3 p-md-6 p-sm-8 p-col-11">
            <p-card>
                <p-header>
                    <div class="p-grid p-fluid p-justify-center">
                        <div class="p-col-6 p-md-6">
                            <img alt="Card" src="assets/img/logo-movired.png">
                        </div>
                    </div>
                </p-header>
                <form *ngIf="showLogin" [formGroup]="loginFormGroup" (ngSubmit)="login()">
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-12">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon"><em class="pi pi-user"></em></span>
                                <input formControlName="userName" type="text" pInputText placeholder="{{'GENERICS.USERNAME' | translate}}">         
                            </div>
                        </div>
                    </div>
                    <div class="p-grid p-fluid p-mt-2">
                        <div class="p-col-12 p-md-12">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon"><em class="pi pi-key"></em></span>
                                <input formControlName="password" type="password" pInputText placeholder="{{'GENERICS.PASSWORD' | translate}}">         
                            </div>
                        </div>
                    </div>
                    
                    <div class="p-grid p-fluid p-justify-center p-mt-2">
                        <div class="p-col-12 p-md-10">
                            <button [disabled]="!loginFormGroup.valid" pButton pRipple type="submit" label="{{'GENERICS.TO_ACCESS' | translate}}" class="p-button-rounded"></button>
                        </div>
                    </div>
                </form>
                <form *ngIf="showNewPasswordRequired" [formGroup]="newPasswordFormGroup" (ngSubmit)="confirmNewPassword()">
                    <p class="text-center">{{'LOGIN.PLEASE_CHANGE_PASSWORD' | translate}}</p>
                    
                    <div class="p-grid p-fluid">
                        <div class="mt-2 p-col-12 p-md-12">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon"><em class="pi pi-key"></em></span>
                                <input formControlName="password" type="password" pInputText placeholder="{{'GENERICS.PASSWORD' | translate}}">         
                            </div>
                        </div>
                    </div>
                    <div *ngIf="newPasswordFormGroup.controls.password.errors" class="text-danger">
                        <div *ngIf="newPasswordFormGroup.controls.password.errors.required">
                            {{'CREATE_USER.VALIDATOR_MESSAGES.PASSWORD' | translate}}</div>
                        <div *ngIf="newPasswordFormGroup.controls.password.errors.minlength">
                            {{'CREATE_USER.VALIDATOR_MESSAGES.PASSWORD_LENGTH' | translate: {length:passwordPolicies.MinimumLength} }}</div>
                        <div *ngIf="newPasswordFormGroup.controls.password.errors.pattern">
                            {{'CREATE_USER.VALIDATOR_MESSAGES.PASSWORD_FORMAT' | translate}}</div>
                    </div>
                    <div class="p-grid p-fluid p-mt-2">
                        <div class="p-col-12 p-md-12">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon"><em class="pi pi-key"></em></span>
                                <input formControlName="confirmPassword" type="password" pInputText placeholder="{{'GENERICS.CONFIRM_PASSWORD' | translate}}">         
                            </div>
                        </div>
                    </div>
                    <div *ngIf="newPasswordFormGroup.controls.confirmPassword.errors" class="text-danger">
                        <div *ngIf="newPasswordFormGroup.controls.confirmPassword.errors.required">
                            {{'CREATE_USER.VALIDATOR_MESSAGES.PASSWORD' | translate}}</div>
                        <div *ngIf="newPasswordFormGroup.controls.confirmPassword.errors.mustMatch">
                            {{'CREATE_USER.VALIDATOR_MESSAGES.CONFIRM_PASSWORD_MATCH' | translate }}</div>
                    </div>

                    <div class="p-grid p-fluid " *ngFor="let attribute of cognitoUserNewPassword.challengeParam.requiredAttributes; let i = index">
                        <div class="p-col-12 p-md-12 mt-2">
                            <div class="p-inputgroup">
                                <input formControlName="{{attribute}}" type="text" pInputText placeholder="{{'GENERICS.'+ attribute.toUpperCase() | translate}}">
                            </div>
                            <div *ngIf="newPasswordFormGroup.controls[attribute].errors" class="text-danger">
                                <div *ngIf="newPasswordFormGroup.controls[attribute].errors.required">{{'CREATE_USER.VALIDATOR_MESSAGES.CUSTOM' | translate: {fieldname:attribute} }}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="p-grid p-fluid p-justify-center p-mt-2">
                        <div class="p-col-12 p-md-10">
                            <button [disabled]="!newPasswordFormGroup.valid" pButton pRipple type="submit" label="{{'GENERICS.SAVE' | translate}}" class="p-button-rounded"></button>
                        </div>
                    </div>
                </form>
                
            </p-card>
        </div>
    </div>
</div>
<p-toast></p-toast>
