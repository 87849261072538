<app-location-bar titulo="{{this.authService.getSelectedPool()}} - {{'TITLE_BAR.LIST_USERS' | translate }}" [showBackButton]="false"></app-location-bar>

<div class="container-fluid p-mt-3">
    <p-table #userTable [rows]="5" [showCurrentPageReport]="true" [rowsPerPageOptions]="[5,10,25,50]"
        styleClass="p-datatable-responsive-demo" [paginator]="true" [filterDelay]="0" [globalFilterFields]=""
        [columns]="colsTableUser" [value]="cognitoUserList">
        <ng-template pTemplate="caption">
            <div class="table-header">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-lg-3">
                            <div class="p-fluid p-inputgroup">
                                <span class="p-inputgroup-addon"><em class="pi pi-search"></em></span>
                                <input type="text" pInputText placeholder="{{'GENERICS.FIND' | translate}}"
                                    (input)="userTable.filterGlobal($event.target.value, 'contains')">
                            </div>

                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 ml-auto mt-4 mt-sm-0 mt-md-0 mt-lg-0">
                            <div class="row">
                                <div class="col-6 col-sm-6 col-lg-6">
                                    <button pButton pRipple label="{{'GENERICS.NEW' | translate}}"
                                        (click)="goToCreateNewUser()" icon="pi pi-plus"
                                        class="form-control p-button-success p-mr-2"></button>
                                </div>

                                <div class="col-6 col-sm-6 col-lg-6">
                                    <app-bulk-user-import (onSave)="addNewUsersImportedCsv($event)">
                                    </app-bulk-user-import>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th class="ellipsis" id="{{translateService.instant(col.header)}}" style="text-align: center;"
                    *ngFor="let col of columns">
                    {{translateService.instant(col.header)}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td style="text-align: center;" class="ellipsis" *ngFor="let col of columns">
                    <div class="ellipsis" [ngSwitch]="col.field">
                        <span title="{{col.header | translate}}" class="p-column-title">{{col.header |
                            translate}}</span>
                        <span title="{{rowData[col.field] | date: formatDate}}" *ngSwitchCase="'UserCreateDate'">
                            {{rowData[col.field] | date: formatDate}}
                        </span>

                        <span title="{{rowData[col.field] | date: formatDateWithHours}}" *ngSwitchCase="'UserLastModifiedDate'">
                            {{rowData[col.field] | date: formatDateWithHours}}
                        </span>

                        <ul *ngSwitchCase="'GroupName'" class="list-group list-group-flush">
                            <li title="{{group}}" style="list-style: none;" class="badge badge-primary p-mt-2"
                                *ngFor="let group of rowData[col.field]">
                                {{group}}
                            </li>
                        </ul>

                        <span title="{{''+rowData[col.field] | translate}}" [ngClass]="{'text-danger':!rowData[col.field],'text-success': rowData[col.field]}"
                            *ngSwitchCase="'Enabled'">
                            {{''+rowData[col.field] | translate}}
                        </span>

                        <span title="{{'COGNITO_USER_STATUS.'+rowData[col.field] | translate}}" *ngSwitchCase="'UserStatus'">
                            {{'COGNITO_USER_STATUS.'+rowData[col.field] | translate}}
                        </span>

                        <div *ngSwitchCase="'Operations'">
                            <button pButton pRipple type="button" icon="pi pi-search" (click)="goToDetailsUser(rowData)"
                                style="margin-left: 0.25rem" class="p-button-rounded"></button>
                            <button pButton pRipple type="button" icon="pi pi-pencil" (click)="goToEditUser(rowData)"
                                style="margin-left: 0.25rem" class="p-button-rounded"></button>
                           <!--  <button *ngIf="rowData['UserStatus'] === 'FORCE_CHANGE_PASSWORD'" title="{{'GENERICS.RESEND_VERIFICATION_EMAIL' | translate}}" pButton pRipple type="button" icon="pi pi-pencil" (click)="goToEditUser(rowData)"
                                style="margin-left: 0.25rem" class="p-button-rounded"></button> -->
                        </div>

                        <span title="{{rowData[col.field]}}" *ngSwitchDefault>
                            {{rowData[col.field]}}
                        </span>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>

</div>
<p-toast [baseZIndex]="50000"></p-toast>

<!-- <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="pacman">
  <p style="font-size: 20px; color: white"></p>
</ngx-spinner> -->