import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-location-bar',
  templateUrl: './location-bar.component.html',
  styleUrls: ['./location-bar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LocationBarComponent implements OnInit {

  @Input() titulo: string;
  @Input() showBackButton: boolean;
  constructor() { }

  ngOnInit(): void {
  }

  backHistory(){
    window.history.back();
  }
}
