import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getSessionStorage, removeSessionStorage, setSessionStorage } from 'src/app/functions/generics';
import { AuthUser } from 'src/app/models/auth-user/auth-user.model';
import { SESSION_SELECTED_POOL_NAME } from 'src/app/utils/const/const';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private tokenName = 'Authorization';
  //public username: string = '';

  constructor(private router: Router) { }

  authenticate(cognitoUser: any) {
    let user: AuthUser = new AuthUser();
    user.token = cognitoUser.signInUserSession.idToken.jwtToken;
    user.username = cognitoUser.username;
    setSessionStorage(this.tokenName, user);
  }

  getToken() {
    return getSessionStorage(this.tokenName);
  }
  isLoggedIn() {
    return this.getToken() instanceof Object;
  }
  logout() {
    removeSessionStorage(this.tokenName);
    this.router.navigate(["/login"]);
  }

  getSelectedPool() {
    return getSessionStorage(SESSION_SELECTED_POOL_NAME).poolName;
  }

  getUsername() {
    if (this.isLoggedIn) {
      let user: AuthUser = this.getToken();
      return user.username;
    } else {
      return null;
    }
  }
}
