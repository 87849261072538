import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MessageCustomService {

  public SUCCESS = "success";
  public ERROR = "error";
  public WARN = "warn";
  public INFO = "info";

  constructor(private messageService: MessageService, private translateService: TranslateService) { }

  successMessage(content: string) {
    this.messageService.add({severity:this.SUCCESS, summary:this.translateService.instant("MESSAGES.TITLE.SUCCESS"), detail:content});
  }

  errorMessage(content: string){
    this.messageService.add({severity:this.ERROR, summary:this.translateService.instant("MESSAGES.TITLE.ERROR"), detail:content});
  }

  warnMessage(content: string){
    this.messageService.add({severity:this.WARN, summary:this.translateService.instant("MESSAGES.TITLE.WARN"), detail:content});
  }

  infoMessage(content: string){
    this.messageService.add({severity:this.INFO, summary:this.translateService.instant("MESSAGES.TITLE.INFO"), detail:content});
  }

  personalizedMessage(severity: string, title: string, content: string, durationTimeInMiliseconds: number, closable: boolean){
    this.messageService.add({severity: severity, summary: title, detail: content ,life: durationTimeInMiliseconds,closable:closable});
  }

  clearMessages(){
    this.messageService.clear();
  }
}
