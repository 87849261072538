import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CreateNewUserComponent } from './create-new-user/create-new-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListUserComponent } from './list-user/list-user.component';
import { DetailsUserComponent } from './details-user/details-user.component';

//PrimeNG Imports
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { InputMaskModule } from 'primeng/inputmask';
import { PanelModule } from 'primeng/panel';
import { CardModule } from 'primeng/card';
import { PickListModule } from 'primeng/picklist';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { MoviredModule } from 'src/app/components/movired.module';
import { ToolbarModule } from 'primeng/toolbar';
import { FileUploadModule } from 'primeng/fileupload';
import { BulkUserImportComponent } from './bulk-user-import/bulk-user-import.component';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  declarations: [CreateNewUserComponent, ListUserComponent, DetailsUserComponent, BulkUserImportComponent],
  imports: [
    CommonModule,
    InputTextModule,
    PanelModule,
    InputMaskModule,
    CardModule,
    PickListModule,
    ButtonModule,
    ReactiveFormsModule,
    ToastModule,
    TableModule,
    ToolbarModule,
    FileUploadModule,
    DialogModule,
    CheckboxModule,
    MoviredModule,
    FormsModule,
    TranslateModule.forChild()

  ]
})
export class UsersModule { }
