import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  constructor(private http: HttpClient) { }

  async signIn(username: string, password: string) {
    let user;
    try {
      user = await Auth.signIn(username, password);
    } catch (error) {
      throw error;
    }
    return user;
  }

  signUp(userToCreate: any) {
    return this.http.post<any>(environment.urlApi + "user/signUp", userToCreate);
  }

  createUser(userToCreate: any) {
    return this.http.post<any>(environment.urlApi + "user", userToCreate);
  }

  async completeNewPassword(cognitoUser, password, attributes) {
    let result
    try {
      result = await Auth.completeNewPassword(cognitoUser, password, attributes);
    } catch (error) {
      throw error;
    }
    return result;
  }

  getUserList(userPoolId: string) {
    return this.http.get<any>(environment.urlApi + "user?userPoolId=" + userPoolId);
  }

  setConfirmUser(userToConfirm: any) {
    return this.http.post<any>(environment.urlApi + "user/confirmUser", userToConfirm);
  }

  setAddUserToGroup(userAndRoles: any) {
    return this.http.post<any>(environment.urlApi + "user/group", userAndRoles);
  }

  setUpdateAttributesUser(attributesToUpdate: any) {
    return this.http.put<any>(environment.urlApi + "admin/atributo", attributesToUpdate);
  }

  setRemoveUserToGroup(userAndRoles: any) {
    return this.http.post<any>(environment.urlApi + "user/removeUser", userAndRoles);
  }

  checkUserIsManage(username: string, userPoolId: string) {
    return this.http.post<any>(environment.urlApi + "user/" + username + "/manage", { userPoolId: userPoolId });
  }

  getGroups(userPoolId: string) {
    return this.http.get<any>(environment.urlApi + "group?userPoolId=" + userPoolId);
  }

  getDescribePool(userPoolId: string) {
    return this.http.get<any>(environment.urlApi + "pool/" + userPoolId);
  }

  getPools() {
    return this.http.get<any>(environment.urlApi + "pool");
  }
}
