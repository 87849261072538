import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms'

//Personalized
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';

//PrimeNG imports
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { BackButtonComponent } from './back-button/back-button.component';
import { LocationBarComponent } from './location-bar/location-bar.component';
import { ToolbarModule } from 'primeng/toolbar';
import { LoaderComponent } from './loader/loader.component';
import { ForbiddenComponent } from './error/forbidden/forbidden.component';
import { MessagesModule } from 'primeng/messages';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, LoginComponent, BackButtonComponent, LocationBarComponent, LoaderComponent, ForbiddenComponent],
  exports: [HeaderComponent, FooterComponent, LoginComponent, BackButtonComponent, LocationBarComponent, LoaderComponent, ForbiddenComponent],
  imports: [
    CommonModule,
    CardModule,
    InputTextModule,
    ButtonModule,
    ReactiveFormsModule,
    ToastModule,
    ToolbarModule,
    MessagesModule,
    TranslateModule.forChild()
  ]
})
export class MoviredModule { }
