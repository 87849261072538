import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

export function comparisonValidator(controlName: string, matchingControlName: string) : ValidatorFn{
    return (group: FormGroup): ValidationErrors => {
       const control1 = group.controls[controlName];
       const control2 = group.controls[matchingControlName];
       if (control1.value !== control2.value) {
          control2.setErrors({notEquivalent: true});
       } else {
          control2.setErrors(null);
       }
       return;
 };
}
