import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  isLoading = new Subject<boolean>();
  hideLoaderCounter = 0;

  show() {
    this.isLoading.next(true);
    this.hideLoaderCounter++;
  }

  hide() {
    this.hideLoaderCounter--;
    if (this.hideLoaderCounter == 0) {
      this.isLoading.next(false);
    }
  }
}
