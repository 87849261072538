import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MessageCustomService } from 'src/app/services/messageCustom/message-custom.service.js';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../../services/users/users.service';
import { CognitoUser } from 'src/app/models/cognito-user/cognito-user.model';
import { ALLOWED_POOLS } from 'src/app/utils/const/const';
import { comparisonValidator } from 'src/app/functions/validators';
import { addCustomFieldsToFormControl, prepareValidatorWithPasswordPolicies, getSessionStorage, setSessionStorage, generatePasswordWithPolicies } from 'src/app/functions/generics';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CognitoService } from 'src/app/services/cognito/cognito.service';

@Component({
  selector: 'app-create-new-user',
  templateUrl: './create-new-user.component.html',
  styleUrls: ['./create-new-user.component.css']
})
export class CreateNewUserComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  // Representa información que nos indica que debemos mostrar los roles a la hora de registrar al usuario o no
  showRolesData: any;
  // Variable que determina si se deben mostrar los roles o no
  showRoles = false;
  // Todos los roles existentes
  allRolesAvailableToSelect: Array<string> = new Array<string>();
  // Roles que se le han asignado durante el alta edición
  assignedRolesUser: Array<string> = new Array<string>();
  editView = false;
  userAuthenticate = false;

  //Roles que el usuario puede seleccionar: La resta de todos los existentes menos los que ya tiene.
  allRolesUserCanSelect: Array<string>;
  cognitoUser: CognitoUser;
  userValuesAttributesToUpdate: any;
  customFields: any;

  inputsCustomAttributes = [];
  inputsRequireds = [];
  passwordPolicies;
  showInputsPassword: boolean;
  listPools: any[];
  existCustomAttributeAllowedPools: boolean;
  assignedPools: any[];
  poolId: string;

  constructor(private formBuilder: FormBuilder, private cognitoService: CognitoService,
    private messageCustomService: MessageCustomService, private translateService: TranslateService,
    private router: Router, private usersService: UsersService, public authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.poolId = this.activatedRoute.snapshot.paramMap.get('poolId');
    this.assignedRolesUser = new Array<string>();
    this.assignedPools = [];
    this.listPools = [];
    this.showInputsPassword = false;
    this.userAuthenticate = this.authService.isLoggedIn();
    this.existCustomAttributeAllowedPools = false;

    this.registerForm = this.formBuilder.group({
      username: ['', Validators.required],
      temporaryPassword: [true]
    });

    await this.getDescribePool();
    this.setViewToShow();

    this.preparePasswordPolicy(this.registerForm);
    addCustomFieldsToFormControl(this.inputsRequireds, this.registerForm)
    addCustomFieldsToFormControl(this.inputsCustomAttributes, this.registerForm)

    await this.getPoolsList();

    if (this.getEditView()) {
      this.getCognitoUserOrReturnMainMenu();
      this.setDataUserRecoveredCognito();
      this.registerForm.controls.username.disable();
    }

    await this.initRoles();
    this.allRolesAvailableToSelect = this.differencesCurrentAndAvailableRol(this.allRolesAvailableToSelect, this.assignedRolesUser);
  }

  preparePasswordPolicy(form: FormGroup) {
    if (!form.get('temporaryPassword').value) {

      const validator = prepareValidatorWithPasswordPolicies(this.passwordPolicies);

      form.addControl('password', new FormControl('', validator));
      form.addControl('confirmPassword', new FormControl('', Validators.required));
      form.setValidators(comparisonValidator('password', 'confirmPassword'));
      this.showInputsPassword = true;
    } else {
      form.setValidators([]);
      form.updateValueAndValidity();
      form.removeControl('password');
      form.removeControl('confirmPassword');
      this.showInputsPassword = false;
    }
  }

  // Rápido acceso a los campos del formulario
  get getControl() {
    return this.registerForm.controls;
  }

  // Indica si un control del formulario tiene un error o no
  getErrorsFromSpecificControl(controlName: any) {
    if (this.registerForm.controls[controlName].errors) {
      return true;
    }
    return false;
  }

  async onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    if (this.assignedRolesUser.length == 0) {
      return;
    }
    if (this.existCustomAttributeAllowedPools) {
      if (this.assignedPools.length == 0) {
        return;
      }
      if (!this.registerForm.controls[ALLOWED_POOLS]) {
        this.registerForm.addControl(ALLOWED_POOLS, new FormControl(this.prepareAllowedPoolsString()))
      } else {
        this.registerForm.controls[ALLOWED_POOLS].setValue(this.prepareAllowedPoolsString());
      }
    }


    // Si estamos en la vista de edición de usuario:
    if (this.getEditView()) {

      await this.updateAttributesUser(this.usersService.setUpdateUserAttributesObject(this.registerForm.getRawValue(), this.poolId));

      if (this.checkIfRolesChanged(this.cognitoUser.GroupName, this.assignedRolesUser)) {
        // Si detecto un cambio con respecto a los roles que el usuario tenía, lo que hago es borrar todos los roles que tenía
        // e introducir los que tenga asignados ahora.
        if (this.cognitoUser.GroupName != null) {
          await this.deleteUserFromGroup(this.usersService.setAddUserAndRolesObject(this.registerForm.controls.username.value, this.cognitoUser.GroupName, this.poolId));
        }

        await this.addRolesToUser(this.usersService.setAddUserAndRolesObject(this.registerForm.controls.username.value, this.assignedRolesUser, this.poolId));
        this.cognitoUser.GroupName = JSON.parse(JSON.stringify(this.assignedRolesUser));

      }

      this.updateDataUserSession();
      this.messageCustomService.successMessage(this.translateService.instant("MESSAGES.SUCCESS.UPDATE_USER"));

    } // Si estamos en la vista de crear un nuevo usuario:
    else {
      let resultSuccess = true;
      let userToCreate: any;

      if (this.showInputsPassword) {
        userToCreate = this.usersService.setSignUpUserAttributesObject(this.registerForm.value, this.poolId);
        await this.signUpUser(userToCreate);
      } else {
        userToCreate = this.usersService.setCreateUserWithTemporaryPassword(this.registerForm.value, generatePasswordWithPolicies(this.passwordPolicies), this.poolId);
        await this.createUser(userToCreate);
      }

      await this.addRolesToUser(this.usersService.setAddUserAndRolesObject(this.registerForm.controls.username.value, this.assignedRolesUser, this.poolId));

      this.messageCustomService.successMessage(this.translateService.instant("MESSAGES.SUCCESS.CREATE_USER"));
      this.resetForm();
    }
  }



  // Este tipo de registro no requiere credenciales.
  signUpUser(userToCreate: any) {
    return new Promise<void>((resolve, reject) => {

      this.cognitoService.signUp(userToCreate).subscribe(
        successResponse => {
          resolve();
        },
        errorResponse => {
          this.messageCustomService.personalizedMessage("error", this.translateService.instant("MESSAGES.ERROR.CREATE_USER"), errorResponse.error, 4500, true);
          reject();
        },
        () => { });
    });

  }

  createUser(userToCreate: any) {
    return new Promise<void>((resolve, reject) => {

      this.cognitoService.createUser(userToCreate).subscribe(
        successResponse => {
          resolve();
        },
        errorResponse => {
          this.messageCustomService.personalizedMessage("error", this.translateService.instant("MESSAGES.ERROR.CREATE_USER"), errorResponse.error.message, 4500, true);
          reject();
        },
        () => { });
    });
  }

  addRolesToUser(userAndRoles: any) {

    return new Promise<void>((resolve, reject) => {

      this.cognitoService.setAddUserToGroup(userAndRoles).subscribe(
        successResponse => {
          resolve();
        },
        errorResponse => {
          this.messageCustomService.errorMessage(this.translateService.instant("MESSAGES.ERROR.ADD_ROLES_USER"));
          reject();
        },
        () => { });
    });
  }

  resetForm() {
    this.submitted = false;
    this.registerForm.reset();
    this.assignedRolesUser = [];
    this.assignedPools = [];
    this.initRoles();
    this.getPoolsList();
    if (!this.editView) {
      this.registerForm.get('temporaryPassword').setValue(true);
    }
    this.preparePasswordPolicy(this.registerForm);
  }

  initRoles() {

    return new Promise<void>((resolve, reject) => {

      this.cognitoService.getGroups(this.poolId).subscribe(
        successResponse => {
          if (successResponse.Groups.length > 0) {
            this.allRolesAvailableToSelect = successResponse.Groups.map(function (group) {
              return group.GroupName;
            })
            resolve();
          }
        },
        errorResponse => {
          reject();
        },
        () => { });
    });
  }

  getCognitoUserOrReturnMainMenu() {
    this.cognitoUser = getSessionStorage("editUser");
    if (this.cognitoUser == null) {
      this.router.navigate(['/login']);
    }
  }

  async setDataUserRecoveredCognito() {
    const attributes = this.cognitoUser.Attributes

    attributes.forEach(attribute => {
      if (attribute.Name === ALLOWED_POOLS && this.existCustomAttributeAllowedPools) {
        this.prepareAssignedAllowedPools(attribute);
      }

      if (this.registerForm.controls[attribute.Name]) {
        this.registerForm.controls[attribute.Name].setValue(attribute.Value)
      }
    });


    if (this.cognitoUser.GroupName != null) {
      //La operación del JSON.parse se hace porque si no, cuando modificas un array se modifica el otro de la misma manera.
      //De este modo son independientes.
      this.assignedRolesUser = JSON.parse(JSON.stringify(this.cognitoUser.GroupName));
    }
    if (this.cognitoUser.Username != null) {
      this.registerForm.controls.username.setValue(this.cognitoUser.Username);
    }

  }

  //Actualiza la información del usuario en la session storage.
  updateDataUserSession() {

    this.cognitoUser.Username = this.registerForm.controls.username.value;

    // Recorre los atributos del usuario de cognito y los va rellenando con la info que hemos introducido en el formulario
    this.cognitoUser.Attributes.forEach(element => {
      if (this.registerForm.controls[element.Name]) {
        element.Value = this.registerForm.controls[element.Name].value;
      }
    });

    this.cognitoUser.GroupName = [];
    this.assignedRolesUser.forEach(element => {
      this.cognitoUser.GroupName.push(element.toString());
    });

    setSessionStorage("editUser", null);
    setSessionStorage("editUser", this.cognitoUser);
  }

  // Calculamos la diferencia de roles: roles disponibles - los que ya tiene el usuario
  differencesCurrentAndAvailableRol(allRolesAvailable: Array<string>, currentRole: Array<string>): Array<string> {
    return allRolesAvailable.filter(x => !currentRole.includes(x));
  }

  //Comprueba si dos arrays tienen lo mismo. Si tienen lo mismo devuelve false.
  checkIfRolesChanged(rolesUserHad: Array<string>, currentRoleSelected: Array<string>): boolean {

    //Este array es el que tiene los roles asignados a  un usuario, si el array es null o undefined, quiere decir que no tenía roles.
    //Por lo tanto le decimos que sí ha cambiado.
    if (rolesUserHad == null || rolesUserHad === undefined) {
      return true;
    }

    let rolesUserHadAsString: string;
    let currentRoleSelectedAsString: string;

    rolesUserHadAsString = rolesUserHad.sort().toString();
    currentRoleSelectedAsString = currentRoleSelected.sort().toString();

    if (rolesUserHadAsString === currentRoleSelectedAsString) {
      return false;
    }

    return true;
  }

  createFormControlCustomFields(customFields: any, form: FormGroup) {
    customFields.forEach(field => {
      let validators = [];
      validators.push(Validators.required);
      if (field.Name === "email") {
        validators.push(Validators.email);
      }
      form.addControl(field.Name, new FormControl('', validators));
    });
  }

  updateAttributesUser(attributesUserToUpdate: any) {

    return new Promise<void>((resolve, reject) => {

      this.cognitoService.setUpdateAttributesUser(attributesUserToUpdate).subscribe(
        successResponse => {
          resolve();
        },
        errorResponse => {
          this.messageCustomService.errorMessage(this.translateService.instant("MESSAGES.ERROR.UPDATE_ATTRIBUTES"));
          reject();
        },
        () => { });
    });
  }

  deleteUserFromGroup(userAndRoles: any) {

    return new Promise<void>((resolve, reject) => {

      this.cognitoService.setRemoveUserToGroup(userAndRoles).subscribe(
        successResponse => {
          resolve();
        },
        errorResponse => {
          this.messageCustomService.errorMessage(this.translateService.instant("MESSAGES.ERROR.REMOVE_ROLES_USER"));
          reject();
        },
        () => { });
    });
  }

  setViewToShow() {
    this.editView = getSessionStorage("editView");
    if (this.editView == null) {
      this.router.navigate(['/login']);
    }
  }

  getEditView() {
    return this.editView;
  }

  // Controla la info metida por teclado
  manageInputKeyboard(event) {
    //69 es la tecla "e". 
    //La tecla "e" es permitida en los controles de tipo input number por eso lo controlamos de esta forma.
    if (event.keyCode === 69) {
      event.preventDefault();
    }
  }

  async getDescribePool() {

    try {
      const schemaAttributes = await this.cognitoService.getDescribePool(this.poolId).toPromise();
      const attributes = schemaAttributes.UserPool.SchemaAttributes
      if (attributes && attributes.length > 0) {
        this.inputsRequireds = attributes.filter(function (attributes) {
          if (attributes.Required && attributes.Name !== 'sub') {
            return attributes
          }
        })

        let that = this;
        this.inputsCustomAttributes = attributes.filter(function (attribute) {
          if (attribute.Name.includes('custom:')) {
            if (attribute.Name === ALLOWED_POOLS) {
              that.existCustomAttributeAllowedPools = true;
            } else {
              return attribute;
            }
          }
        })
      }

      const policies = schemaAttributes.UserPool.Policies.PasswordPolicy;
      if (policies) {
        this.passwordPolicies = policies;
      }


    } catch (error) {
      throw new Error(error);
    }

  }

  getTypeInputOfAttributeDataType(attribute: string) {
    let typeInput;
    switch (attribute) {
      case "String":
        typeInput = "text"
        break;
      case "Number":
        typeInput = "number"
        break;
      case "DateTime":
        typeInput = "date"
        break;
      case "Boolean":
        typeInput = "checkbox"
        break;
      default:
        typeInput = "text"
        break;
    }
    return typeInput

  }

  async getPoolsList() {
    try {
      const listPools = await this.cognitoService.getPools().toPromise();
      this.listPools = listPools.UserPools;
    } catch (error) {
      this.messageCustomService.errorMessage(error);
    }
  }

  prepareAllowedPoolsString() {
    let arrayAllowedPoolsObjects = [];
    this.assignedPools.forEach(function (pool) {
      arrayAllowedPoolsObjects.push({ "poolName": pool['Name'], "poolId": pool['Id'] });
    })
    return JSON.stringify(arrayAllowedPoolsObjects);
  }

  prepareAssignedAllowedPools(attribute) {
    if (this.getEditView) {
      const allowedPools = JSON.parse(attribute.Value);
      if (Array.isArray(allowedPools) && allowedPools.length > 0) {
        for (let x = 0; x < allowedPools.length; x++) {
          const poolName = allowedPools[x]['poolName'];
          const poolId = allowedPools[x]['poolId']
          this.assignedPools.push({ "Name": poolName, "Id": poolId });
          this.removePoolsThatAreAssigned(poolId);
        }
      }
    }
  }

  removePoolsThatAreAssigned(poolId: string) {
    for (let i = 0; i < this.listPools.length; i++) {
      if (poolId === this.listPools[i].Id) {
        this.listPools.splice(i, 1);
      }
    }
  }
}
