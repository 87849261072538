import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MoviredModule } from './components/movired.module';
import { UsersModule } from './modules/users/users.module';
import { MessageService } from 'primeng/api';
import { SpinnerService } from './services/spinner/spinner.service';

import Amplify from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import { AWS_AUTH } from 'config/aws/aws-exports';
import { PoolsModule } from './modules/pools/pool.module';
import { HttpRequestInterceptor } from './interceptors/httpRequest/httpRequest.interceptor';

/* Configure Amplify resources */
Amplify.configure(AWS_AUTH);
Auth.configure(AWS_AUTH)

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MoviredModule,
    PoolsModule,
    UsersModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [MessageService, { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }, SpinnerService],
  bootstrap: [AppComponent]
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
