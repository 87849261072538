import { Injectable } from '@angular/core';
import { iterateJsonAndAddKeyValueToArray } from 'src/app/functions/generics';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor() { }

  // Se crea el objeto adecuado para asignarle los roles a un usuario registrado en Cognito
  setAddUserAndRolesObject(username: string, roles: Array<String>, poolId: string): any {
    let userAndRoles = {
      Username: username,
      UserPoolId: poolId,
      Roles: [],
    };

    userAndRoles.Roles = roles;

    return userAndRoles;
  }

  // Se crea el objeto adecuado para confirmar un usuario registrado en Cognito
  setConfirmUserObject(username: string, poolId: string): any {
    let userToConfirm = {
      Username: username,
      UserPoolId: poolId,
    };

    return userToConfirm;
  }

  setUpdateUserAttributesObject(userAttributes: any, poolId: string): any {

    let userUpdateObject = {
      UserAttributes: [
      ],
      Username: userAttributes.username,
      UserPoolId: poolId
    };

    iterateJsonAndAddKeyValueToArray(userAttributes, userUpdateObject.UserAttributes);

    return userUpdateObject;
  }

  setCreateUserWithTemporaryPassword(userAttributes: any, password: string, poolId: string) {
    let userData = {
      UserPoolId: poolId,
      TemporaryPassword: password,
      Username: userAttributes.username,
      UserAttributes: []
    }

    iterateJsonAndAddKeyValueToArray(userAttributes, userData.UserAttributes);

    return userData;
  }

  setSignUpUserAttributesObject(userAttributes: any, poolId: string): any {
    let userData = {
      Username: userAttributes.username,
      Password: userAttributes.password,
      UserAttributes: []
    }

    iterateJsonAndAddKeyValueToArray(userAttributes, userData.UserAttributes);
    const userToCreate = { userPoolId: poolId, userData: userData };

    return userToCreate;
  }
}
