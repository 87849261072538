<app-location-bar *ngIf="!editView" titulo="{{this.authService.getSelectedPool()}} - {{'GENERICS.REGISTRY' | translate}}" [showBackButton]="true">
</app-location-bar>
<app-location-bar *ngIf="editView" titulo="{{this.authService.getSelectedPool()}} - {{'TITLE_BAR.EDIT_USER' | translate: {username:cognitoUser?.Username} }}"
    [showBackButton]="true"></app-location-bar>

<form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6 p-lg-6">
            <label>{{'GENERICS.USERNAME' | translate}} </label>
            <input type="text" formControlName="username" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && getControl.username.errors }" pInputText />
            <div *ngIf="submitted && getControl.username.errors" class="text-danger">
                <div *ngIf="getControl.username.errors.required">
                    {{'CREATE_USER.VALIDATOR_MESSAGES.USERNAME' | translate}}</div>
            </div>
        </div>
        <ng-container *ngFor="let inputRequired of inputsRequireds">
            <div class="p-col-12 p-md-6 p-lg-6" [ngSwitch]="inputRequired.Name">
                <label> {{'GENERICS.'+ inputRequired.Name.toUpperCase() | translate}} </label>
                <input *ngSwitchCase="'email'" type="email" formControlName="email" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && getErrorsFromSpecificControl('email')}" pInputText />
                <input *ngSwitchDefault type="{{getTypeInputOfAttributeDataType(inputRequired.AttributeDataType)}}"
                    formControlName="{{inputRequired.Name}}" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && getErrorsFromSpecificControl(inputRequired.Name) }"
                    pInputText />
                <div *ngIf="submitted && getErrorsFromSpecificControl(inputRequired.Name)" class="text-danger">
                    <div *ngIf="'getControl' + inputRequired.Name + '.errors.required'">
                        {{'CREATE_USER.VALIDATOR_MESSAGES.CUSTOM' | translate: {fieldname:inputRequired.Name} }}</div>
                    <div *ngIf="inputRequired.Name === 'email' && getControl.email.errors.email">
                        {{'CREATE_USER.VALIDATOR_MESSAGES.EMAIL_FORMAT' | translate}}</div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="userAuthenticate">
            <ng-container *ngFor="let customAttribute of inputsCustomAttributes">
                <div class="p-col-12 p-md-6 p-lg-6">
                    <label> {{customAttribute.Name}} </label>
                    <input class="form-control"
                        type="{{getTypeInputOfAttributeDataType(customAttribute.AttributeDataType)}}"
                        formControlName="{{customAttribute.Name}}"
                        [ngClass]="{'is-invalid': submitted && getErrorsFromSpecificControl(customAttribute.Name)}" />

                    <div *ngIf="submitted && getErrorsFromSpecificControl(customAttribute.Name)" class="text-danger">
                        <div *ngIf="'getControl' + customAttribute.Name + '.errors.required'">
                            {{'CREATE_USER.VALIDATOR_MESSAGES.CUSTOM' | translate: {fieldname:customAttribute.Name} }}
                        </div>
                    </div>
                </div>
            </ng-container>

        </ng-container>

        <ng-container *ngIf="!editView && !registerForm.get('temporaryPassword').value">
            <div class="p-col-12 p-md-6 p-lg-6">
                <label> {{'GENERICS.PASSWORD' | translate}} </label>
                <input type="password" formControlName="password" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && getControl.password.errors }" pInputText />
                <div *ngIf="submitted && getControl.password.errors" class="text-danger">
                    <div *ngIf="getControl.password.errors.required">
                        {{'CREATE_USER.VALIDATOR_MESSAGES.PASSWORD' | translate}}</div>
                    <div *ngIf="getControl.password.errors.minlength">
                        {{'CREATE_USER.VALIDATOR_MESSAGES.PASSWORD_LENGTH' | translate: {length:passwordPolicies.MinimumLength} }}
                    </div>
                    <div *ngIf="getControl.password.errors.pattern">
                        {{'CREATE_USER.VALIDATOR_MESSAGES.PASSWORD_FORMAT' | translate}}</div>
                    <div *ngIf="getControl.password.errors.pattern">
                        {{'CREATE_USER.VALIDATOR_MESSAGES.PASSWORD_FORMAT_SPECIAL_CHARACTERS' | translate}}</div>
                </div>
            </div>

            <div class="p-col-12 p-md-6 p-lg-6">
                <label> {{'GENERICS.CONFIRM_PASSWORD' | translate}} </label>
                <input type="password" formControlName="confirmPassword" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && getControl.confirmPassword.errors }" pInputText />
                <div *ngIf="submitted && getControl.confirmPassword.errors" class="text-danger">
                    <div *ngIf="getControl.confirmPassword.errors.required">
                        {{'CREATE_USER.VALIDATOR_MESSAGES.CONFIRM_PASSWORD' | translate}}</div>
                    <div *ngIf="getControl.confirmPassword.errors.notEquivalent">
                        {{'CREATE_USER.VALIDATOR_MESSAGES.CONFIRM_PASSWORD_MATCH' | translate}}</div>
                </div>
            </div>
        </ng-container>

        <div *ngIf="!editView" style="line-height: 30px;" class="p-col-12 p-md-6 p-lg-6">
            <label></label>
            <div>
                <p-checkbox (onChange)="preparePasswordPolicy(registerForm)"
                    label="{{'GENERICS.TEMPORARY_PASSWORD' | translate}}" formControlName="temporaryPassword"
                    binary="true" inputId="binary"></p-checkbox>
            </div>
        </div>


        <div *ngIf="existCustomAttributeAllowedPools" class="p-col-12 p-md-12 p-lg-12 mb-2">
            <p-pickList [showTargetFilter]="false" [showSourceControls]="false" [showTargetControls]="false"
                [source]="listPools" [target]="assignedPools" sourceHeader="{{'POOL.AVAILABLE' | translate}}"
                targetHeader="{{'POOL.SELECTED' | translate}}" [responsive]="true" dragdrop="true"
                [sourceStyle]="{'height':'200px'}" [targetStyle]="{'height':'200px'}">
                <ng-template let-pool pTemplate="item">
                    <div class="ui-helper-clearfix">
                        <div>
                            {{pool.Name}}
                        </div>
                    </div>
                </ng-template>
            </p-pickList>
            <div class="text-danger" *ngIf="submitted && this.assignedPools.length == 0">
                {{'CREATE_USER.VALIDATOR_MESSAGES.POOLS_MIN' | translate}}</div>
        </div>

        <div *ngIf="editView || userAuthenticate" class="p-col-12 p-md-12 p-lg-12">
            <p-pickList [showTargetFilter]="false" [showSourceControls]="false" [showTargetControls]="false"
                [source]="allRolesAvailableToSelect" [target]="assignedRolesUser"
                sourceHeader="{{'ROLES.AVAILABLE' | translate}}" targetHeader="{{'ROLES.ASSIGNED_ROLES' | translate}}"
                [responsive]="true" dragdrop="true" [sourceStyle]="{'height':'200px'}"
                [targetStyle]="{'height':'200px'}">

                <ng-template let-rol pTemplate="item">
                    <div class="ui-helper-clearfix">
                        <div>
                            {{rol}}
                        </div>
                    </div>
                </ng-template>
            </p-pickList>
            <div class="text-danger" *ngIf="submitted && this.assignedRolesUser.length == 0">
                {{'CREATE_USER.VALIDATOR_MESSAGES.ROLES_MIN' | translate}}</div>
        </div>

        <div class="p-col-12 p-md-4 p-lg-4"></div>

        <div *ngIf="!editView" class="p-col-12 p-md-4 p-lg-4">
            <button pButton pRipple type="submit" label="{{'GENERICS.REGISTER' | translate}}"></button>
        </div>
        <div *ngIf="editView" class="p-col-12 p-md-4 p-lg-4">
            <button pButton pRipple type="submit" label="{{'GENERICS.SAVE' | translate}}"></button>
        </div>
        <div class="p-col-12 p-md-4 p-lg-4"></div>
    </div>
</form>
<p-toast></p-toast>