import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Auth } from 'aws-amplify';
import { removeSessionStorage, setSessionStorage } from 'src/app/functions/generics';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SESSION_POOLS_NAME, SESSION_SELECTED_POOL_NAME } from '../../../utils/const/const';

@Component({
  selector: 'app-pools-list',
  templateUrl: './pools-list.component.html',
  styleUrls: ['./pools-list.component.css']
})
export class PoolsListComponent implements OnInit {

  pools = null;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, public authService: AuthService) { }

  ngOnInit(): void {
    removeSessionStorage(SESSION_SELECTED_POOL_NAME);
    this.checkIfHasCustomAllowedPools();
  }

  selectPool(pool) {
    setSessionStorage(SESSION_SELECTED_POOL_NAME, pool);
    this.router.navigate([pool.poolId, 'user'], { relativeTo: this.activatedRoute });
  }

  checkIfHasCustomAllowedPools() {
    const parent = this;
    Auth.currentUserInfo().then((cognitoUser) => {
      parent.pools = [];
      if (cognitoUser.attributes['custom:allowedPools']) {
        const allowedPools = JSON.parse(cognitoUser.attributes['custom:allowedPools']);
        if (allowedPools.length > 0) {
          setSessionStorage(SESSION_POOLS_NAME, allowedPools);
          parent.pools = allowedPools;
          if (allowedPools.length == 1) {
            parent.selectPool(allowedPools[0]);
          }
        }
      }
    })
  }

  showNotAvailablePoolslMessage() {
    return this.pools !== null && this.pools.length == 0;
  }
}
