<div class="container-fluid">
    <p-messages severity="error">
        <ng-template pTemplate>
            <div class="p-ml-2 text-center">
                <span>{{'FORBIDDEN.NOT_HAVE_PERMISSIONS' | translate }}  </span>
                <a href="/"> <-- {{'GENERICS.BACK_TO_HOME' | translate }}</a>
            </div>
        </ng-template>
    </p-messages>
</div>
