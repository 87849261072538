<p-button styleClass="form-control bg-info" icon="pi pi-upload" iconPos="left" class="p-button-help"
    (onClick)="showMultipleUserCreationPopup()" label="{{'GENERICS.IMPORT' | translate}}"></p-button>

<p-dialog [modal]="true" header="{{'MULTIPLE_USERS_CREATION.MULTIPLE_USER_GENERATION' | translate}}"
    [(visible)]="showMultipleUserCreationDialog" [style]="{width: '40vw', 'min-width': '576px'}" [baseZIndex]="10001">
    <p-fileUpload #fileUploader styleClass="bg-info" chooseIcon="pi pi-search" class="p-button-help" accept=".xlsx"
        maxFileSize="1000000" (uploadHandler)="loadFiles()" multiple="multiple" [auto]="false"
        chooseLabel="{{'GENERICS.SELECT_FILE' | translate}}" cancelLabel="{{'GENERICS.CANCEL' | translate}}"
        uploadLabel="{{'GENERICS.PROCESS' | translate}}" [customUpload]="true">
        <ng-template let-file pTemplate="file">
            <div>
                {{file.name}} <i aria-hidden="true" class="pi pi-times-circle float-right"
                    style="color: red; cursor: pointer;" (click)="removeFile(file)"></i>
            </div>
        </ng-template>
        <ng-template pTemplate="content" let-files>
            <div *ngIf="fileUploader._files.length == 0" [style]="{height: '8vw'}">
                <p class="center_middle">{{'MULTIPLE_USERS_CREATION.DRAG_AND_DROP' | translate}}</p>
            </div>
        </ng-template>
    </p-fileUpload>
    <div class="row">
        <div class="col-6 mt-2">
            <p-button type="button" label="{{'MULTIPLE_USERS_CREATION.DOWNLOAD_TEMPLATE' | translate}}" 
                styleClass="p-button-outlined p-button-info" icon="pi pi-download" (onClick)="donwloadTemplate()">
            </p-button>
        </div>
        <div class="col-6 mt-3">
            <p-checkbox value="Generar contraseñas aleatoria" binary="true" [(ngModel)]="aleatoryPassword" inputId="aleatoryPassword"></p-checkbox>
            <span class="ml-2" for="aleatoryPassword">Generar contraseñas aleatorias</span>
        </div>
    </div>
    
    
</p-dialog>

<p-dialog [modal]="true" (onHide)="cleanValues()" header="{{'MULTIPLE_USERS_CREATION.IMPORTED_USERS' | translate}}"
    [(visible)]="showUsersToCreateDialog" [style]="{width: '90vw'}" [baseZIndex]="10000">
    
    <div class="container-fluid" *ngIf="showPreviewUsers && usersToRegister.length > 0">
        <p-table #dt [rows]="4" [showCurrentPageReport]="true" [rowsPerPageOptions]="[4,8,10,25,50]"
            styleClass="p-datatable-responsive" [paginator]="true" [filterDelay]="0" [globalFilterFields]=""
            [style]="{height: '67vh'}" [columns]="colsTableNewUser" [value]="usersToRegister">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="{{translateService.instant(col.header)}}" style="text-align: center;"
                        *ngFor="let col of columns">
                        {{translateService.instant(col.header)}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex">
                <tr>
                    <td style="text-align: center;" *ngFor="let col of columns">

                        <div [ngSwitch]="col.field">

                            <ul *ngSwitchCase="'roles'" class="list-group list-group-flush">
                                <li title="{{group}}" style="list-style: none;" class="badge badge-primary p-mt-2"
                                    *ngFor="let group of rowData[col.field]">
                                    {{group}}
                                </li>
                            </ul>

                            <div style="font-size: 11px;" *ngSwitchCase="'UserAttributes'">
                                <dl *ngIf="rowData[col.field]">
                                    <div class="row" *ngFor="let attribute of rowData[col.field]">
                                        <div *ngIf="!attribute.Name.includes('custom')" class="col-6 text-right">
                                            <strong><span title="{{attribute.Name}}">{{attribute.Name}}</span></strong>
                                        </div>
                                        <div *ngIf="!attribute.Name.includes('custom')" class="col-6 text-left">
                                            <span title="{{attribute.Value}}">{{attribute.Value}}</span>
                                        </div>
                                    </div>
                                </dl>
                            </div>

                            <div style="font-size: 11px;" *ngSwitchCase="'CustomUserAttributes'">
                                <dl *ngIf="rowData[col.subfield]">
                                    <div class="row" *ngFor="let attribute of rowData[col.subfield]">
                                        <div *ngIf="attribute.Name.includes('custom')" class="col-6 text-right">
                                            <strong><span title="{{attribute.Name}}">{{attribute.Name}}</span></strong>
                                        </div>
                                        <div *ngIf="attribute.Name.includes('custom')" class="col-6 text-left">
                                            <span title="{{attribute.Value}}">{{attribute.Value}}</span>
                                        </div>
                                    </div>
                                </dl>
                            </div>

                            <div *ngSwitchCase="'Operations'">
                                <button pButton pRipple type="button" icon="pi pi-check"
                                    (click)="saveUser(rowData,i,true)" style="margin-left: 0.25rem"
                                    class="p-button-rounded"></button>
                                <button pButton pRipple type="button" icon="pi pi-times" (click)="deleteUser(i)"
                                    style="margin-left: 0.25rem" class="p-button-rounded"></button>
                            </div>

                            <span *ngSwitchDefault>
                                {{rowData[col.field]}}
                            </span>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="container-fluid" *ngIf="!showPreviewUsers">
        <ul *ngIf="usersRegistered.length > 0">
            <li>{{'MULTIPLE_USERS_CREATION.REGISTERED_USERS' | translate}}</li>
            <ul>
                <li *ngFor="let user of usersRegistered">{{user.Username}}</li>
            </ul>
        </ul>
        <ul *ngIf="errorToRegisterUser.length > 0">
            <li>{{'MULTIPLE_USERS_CREATION.FAILED_REGISTER_USERS' | translate}}</li>
            <ul>
                <li *ngFor="let user of errorToRegisterUser">{{user}}</li>
            </ul>
        </ul>
        <ul *ngIf="errorToAddRolesToUser.length > 0">
            <li>{{'MULTIPLE_USERS_CREATION.ERROR_ADDING_ROLES_TO_USERS' | translate}}</li>
            <ul>
                <li *ngFor="let user of errorToAddRolesToUser">{{user}}</li>
            </ul>
        </ul>
    </div>
    <ng-template pTemplate="footer">
        <div *ngIf="showPreviewUsers && usersToRegister.length > 0">
            <p-button icon="pi pi-check" (click)="saveAll()" [disabled]="usersToRegister.length === 0"
                label="{{'GENERICS.SAVE_ALL' | translate}}" class="p-button-text"></p-button>
            <p-button icon="pi pi-times" (click)="showUsersToCreateDialog=false"
                label="{{'GENERICS.CANCEL' | translate}}">
            </p-button>
        </div>
    </ng-template>
</p-dialog>