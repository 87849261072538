import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { finalize, catchError } from "rxjs/operators";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AuthService } from 'src/app/services/auth/auth.service';


/**
 * Custom Http interceptor
 */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    //countSpinner : number = 0;

    constructor(private router: Router, public spinnerService: SpinnerService, private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var authReq = req.clone();
        if (req.url.includes(environment.urlApi)) {
            this.spinnerService.show();
            const headers = new HttpHeaders({
                'Authorization': this.authService.getToken() ? this.authService.getToken().token : ''
            });
            authReq = req.clone({ headers });
            return next.handle(authReq).pipe(
                catchError(x => this.handleAuthError(x)),
                finalize(() => this.spinnerService.hide())
            );
        } else {
            return next.handle(req);
        }

    }



    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        //handle your auth error or rethrow
        if (err.status === 401 || err.status === 403 || err.status === 0) {
            this.authService.logout();
            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
            return of(err.message); // or EMPTY may be appropriate here
        }
        return throwError(err);
    }
}
