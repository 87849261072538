<header>
    <nav class="navbar navbar-light" style="background-color: #B7E0B8;">
        <!-- Navbar content -->
        <a style="font-size: 1rem; font-weight: bold;" class="navbar-brand" href="#">
            <em style="font-size: 1.2rem;cursor: pointer;font-weight: bold;" title="Home" class="pi pi-home"></em>
            {{'PROJECT_NAME' | translate}}
        </a>

        <div *ngIf="this.authService.getUsername()" class="form-inline">
            <div class="mr-3">
                <strong>
                    <em class="pi pi-user"></em>
                    <span> {{this.authService.getUsername()}}</span>
                </strong>
            </div>

            <div class="mt-1">
                <strong>
                    <em (click)="this.authService.logout()" style="font-size: 1.3rem;cursor: pointer;font-weight: bold;"
                        title="Log out" class="pi pi-power-off"></em>
                </strong>
            </div>
        </div>

    </nav>
</header>