import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateNewUserComponent } from './modules/users/create-new-user/create-new-user.component';
import { LoginComponent } from './components/login/login.component';
import { ListUserComponent } from './modules/users/list-user/list-user.component';
import { DetailsUserComponent } from './modules/users/details-user/details-user.component';
import { PoolsListComponent } from './modules/pools/pools-list/pools-list.component';
import { ForbiddenComponent } from './components/error/forbidden/forbidden.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { PoolGuard } from './guards/pool/pool.guard';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'pool', canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', component: PoolsListComponent },
      {
        path: ':poolId', canActivate: [PoolGuard],
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'user' },
          {
            path: 'user',
            children: [
              { path: '', pathMatch: 'full', component: ListUserComponent },
              { path: ':username', pathMatch: 'full', component: DetailsUserComponent }
            ]
          },
          { path: 'new-user', pathMatch: 'full', component: CreateNewUserComponent }
        ]
      }
    ]
  },
  { path: 'forbidden', component: ForbiddenComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }