import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FileUpload } from 'primeng/fileupload';
import { Table } from 'primeng/table';
import { generatePasswordWithPolicies, getSessionStorage, removeInitialAndLastSpacingAndQuotesAndSplit } from 'src/app/functions/generics';
import { MessageCustomService } from 'src/app/services/messageCustom/message-custom.service';
import { UsersService } from 'src/app/services/users/users.service';
import { SESSION_SELECTED_POOL_NAME } from 'src/app/utils/const/const';
import { ExcelService } from 'src/app/services/excel/excel.service'
import { ActivatedRoute } from '@angular/router';
import { CognitoService } from 'src/app/services/cognito/cognito.service';

@Component({
  selector: 'app-bulk-user-import',
  templateUrl: './bulk-user-import.component.html',
  styleUrls: ['./bulk-user-import.component.css']
})
export class BulkUserImportComponent implements OnInit {

  usersToRegister = [];
  showUsersToCreateDialog: boolean;
  colsTableNewUser;
  errorToRegisterUser = [];
  errorToAddRolesToUser = [];
  usersRegistered = [];
  @ViewChild('dt')
  dataTable: Table
  showPreviewUsers: boolean;
  @Output()
  onSave = new EventEmitter();
  @ViewChild('fileUploader')
  fileUploader: FileUpload;
  rolesApi = [];
  showMultipleUserCreationDialog: boolean;
  processedFilesSuccessfully: boolean;
  poolId: string;
  aleatoryPassword: boolean;
  passwordPolicies: any;

  constructor(private userService: UsersService, private cognitoService: CognitoService, private activatedRoute: ActivatedRoute,
    public translateService: TranslateService, private messageCustomService: MessageCustomService, private usersService: UsersService,
    private excelService: ExcelService) { }

  ngOnInit(): void {
    this.aleatoryPassword = true;
    this.poolId = this.activatedRoute.snapshot.paramMap.get('poolId');
    this.showUsersToCreateDialog = false;
    this.showPreviewUsers = true;
    this.showMultipleUserCreationDialog = false;
    this.prepareColsTableNewUsers();
  }

  async loadFiles() {
    const schemaAttributes = await this.cognitoService.getDescribePool(this.poolId).toPromise();
    const policies = schemaAttributes.UserPool.Policies.PasswordPolicy;
    if (policies) {
      this.passwordPolicies = policies;
    }

    for (let i = 0; i < this.fileUploader._files.length; i++) {
      const fileToRead = this.fileUploader._files[i];
      await this.getRolesApi();
      const fileReader = new FileReader();
      fileReader.onload = this.onFileLoad.bind(this);
      fileReader.readAsBinaryString(fileToRead);
    }
    this.showMultipleUserCreationDialog = false;
    this.fileUploader.clear();
    this.showUsersToCreateDialog = true;
  }

  prepareColsTableNewUsers() {
    this.colsTableNewUser = [
      { field: 'Username', header: 'GENERICS.USERNAME' },
      { field: 'roles', header: 'GENERICS.GROUPS' },
      { field: 'UserAttributes', header: 'GENERICS.ATTRIBUTES' },
      { field: 'CustomUserAttributes', subfield: 'UserAttributes', header: 'GENERICS.CUSTOM_ATTRIBUTES' },
      { field: 'Operations', header: 'GENERICS.OPERATIONS' }
    ];
  }

  async saveUser(user, index: number, singleUser: boolean) {
    let createdUser: boolean = true;
    let confirmedUser: boolean = true;
    let rolesAddedToUser: boolean = true;
    try {
      if (!this.aleatoryPassword){
        const userToCreate = { userPoolId: this.poolId, userData: { ...user } };
        delete userToCreate.userData.roles;
        await this.cognitoService.signUp(userToCreate).toPromise();
      }else{
        const userToCreate = {
          UserPoolId: this.poolId,
          TemporaryPassword: generatePasswordWithPolicies(this.passwordPolicies),
          Username: user.Username,
          UserAttributes: user.UserAttributes
        }
        await this.cognitoService.createUser(userToCreate).toPromise();
      }

      if (!singleUser) this.usersRegistered.push(user)
    } catch (e) {
      createdUser = false;
      if (singleUser) {
        this.messageCustomService.errorMessage(e.error);
      } else {
        this.errorToRegisterUser.push(user.Username + ": " + e.error);
      }
    }

    if (createdUser) {
      if (user.roles && user.roles.length > 0) {
        try {
          await this.cognitoService.setAddUserToGroup(this.userService.setAddUserAndRolesObject(user.Username, user.roles, this.poolId)).toPromise();
        } catch (e) {
          rolesAddedToUser = false;
          if (singleUser) {
            this.messageCustomService.errorMessage(e.error.message);
          } else {
            this.errorToAddRolesToUser.push(`${user.Username}: ${e.error.message}`);
          }
        }
      }
    }

    if (singleUser && createdUser && rolesAddedToUser && confirmedUser) {
      this.messageCustomService.successMessage(this.translateService.instant("MESSAGES.SUCCESS.CREATE_USER"))
      this.deleteUser(index);
    }

    if (createdUser) {
      this.onSave.emit(user);
    }
  }

  deleteUser(index) {
    this.usersToRegister.splice(index, 1);
    this.dataTable._totalRecords = this.usersToRegister.length;
  }

  saveAll() {
    for (var x = 0; x < this.usersToRegister.length; x++) {
      this.saveUser(this.usersToRegister[x], x, false);
    }

    this.showPreviewUsers = false;
  }

  async onFileLoad(fileLoadedEvent) {
    const excelData = this.excelService.importExcelFile(fileLoadedEvent.target.result);
    try {
      const loadedUsers = await this.readExcelLinesAndGetCognitoUsers(excelData);
      this.usersToRegister = this.usersToRegister.concat(loadedUsers);
    } catch (e) {
      this.messageCustomService.errorMessage(e.message);
      this.processedFilesSuccessfully = false;
    }
  }

  async readExcelLinesAndGetCognitoUsers(lines) {
    const cognitoUsers = [];
    const headers = lines[0];

    for (var x = 1; x < lines.length; x++) {
      const line = lines[x];
      if (line.length > 0) {
        cognitoUsers.push(this.generateCognitoUserFromAttributes(line, headers));
      }
    }
    return cognitoUsers;
  }

  getKeyValueCustomAttributes(username: string, customAttributes: any[]) {
    const result = {};

    customAttributes.forEach(element => {
      const keyValueCustom = element.split(":");
      if (keyValueCustom.length !== 2) {
        throw new Error(`Error en el usuario: ${username}, el atributo custom:${element} tiene un formato incorrecto`)
      }
      if (keyValueCustom[1] === "" || keyValueCustom[0] === "") {
        throw new Error(`Error en el usuario: ${username}, el atributo custom:${element} no tiene valor`)
      }
      const key = 'custom:' + keyValueCustom[0];
      const value = keyValueCustom[1];
      result[key] = value;
    });
    return result;
  }

  generateCognitoUserFromAttributes(attributesValue, headers) {
    const cognitoUser = { UserAttributes: [] };
    const rolesSeparator = ","
    for (var e = 0; e < headers.length; e++) {
      const attribute = headers[e];
      switch (attribute) {
        case "Username":
        case "Password":
          cognitoUser[headers[e]] = attributesValue[e];
          break;
        case "roles":
          cognitoUser["roles"] = removeInitialAndLastSpacingAndQuotesAndSplit(attributesValue[e], rolesSeparator);
          if (cognitoUser["roles"] !== undefined && cognitoUser["roles"].length > 0) {
            this.checkExistRole(cognitoUser["roles"]);
          }
          break;
        default:
          let attributeToCreate = {};
          attributeToCreate = {
            Name: headers[e],
            Value: !isNaN(attributesValue[e]) ? attributesValue[e].toString() : attributesValue[e]
          };
          cognitoUser.UserAttributes.push(attributeToCreate);
          break;
      }
    }
    return cognitoUser;
  }


  checkExistRole(roles) {
    roles.forEach(rol => {
      let resultado = false;
      this.rolesApi.forEach(rolApi => {
        if (rol === rolApi.GroupName) {
          resultado = true;
        }
      });
      if (!resultado) {
        this.showUsersToCreateDialog = false;
        throw new Error(`El rol: ${rol} no existe`)
      }
    })
  }

  cleanValues() {
    this.errorToRegisterUser = [];
    this.errorToAddRolesToUser = [];
    this.usersRegistered = [];
    this.usersToRegister = [];
    this.showPreviewUsers = true;
  }

  async getRolesApi() {
    const resultado = await this.cognitoService.getGroups(this.poolId).toPromise();
    if (resultado.Groups.length > 0) {
      this.rolesApi = resultado.Groups;
    }
  }

  showMultipleUserCreationPopup() {
    this.showMultipleUserCreationDialog = true;
  }

  removeFile(file: File) {
    const index = this.fileUploader.files.indexOf(file);
    this.fileUploader.remove(null, index);
  }

  async donwloadTemplate() {
    const listAttributes = [];
    listAttributes.push({ "Username": null });
    if (!this.aleatoryPassword) {
      listAttributes.push({ "Password": null });
    }
    listAttributes.push({ "roles": null });

    try {
      const schemaAttributes = await this.cognitoService.getDescribePool(this.poolId).toPromise();
      const attributes = schemaAttributes.UserPool.SchemaAttributes;
      if (attributes) {
        attributes.forEach(attribute => {
          if ((attribute.Required || attribute.Name.includes('custom:')) && attribute.Name !== 'sub') {
            const attributeToAdd = {};
            attributeToAdd[attribute.Name] = null;
            listAttributes.push(attributeToAdd);
          }
        });
      }
    } catch (error) {
      throw new Error(error);
    }
    this.excelService.exportExcel(listAttributes, `${getSessionStorage(SESSION_SELECTED_POOL_NAME).poolName}_Bulk_User_Import_Template`);
  }
}
