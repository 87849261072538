import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MIN_LENGTH_PASSWORD } from '../../utils/const/const';
import { MessageCustomService } from 'src/app/services/messageCustom/message-custom.service.js';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AWS_AUTH } from 'config/aws/aws-exports';
import { MustMatch } from 'src/app/functions/validators';
import { getObjectOfAttributesToFormControl, prepareValidatorWithPasswordPolicies } from 'src/app/functions/generics';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CognitoService } from 'src/app/services/cognito/cognito.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginFormGroup: FormGroup;
  newPasswordFormGroup: FormGroup;
  showNewPasswordRequired: boolean;
  showLogin: boolean;
  showForgotPassword: boolean;
  passwordPolicies: any;
  cognitoUserNewPassword: any;

  constructor(private formBuilder: FormBuilder, private cognitoService: CognitoService,
    private messageCustomService: MessageCustomService, private translateService: TranslateService,
    private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    this.showLogin = true;
    this.showForgotPassword = false;
    this.showNewPasswordRequired = false;
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/pool']);
    }
    this.createFormLogin();
  }

  confirmNewPassword() {
    const attributes = getObjectOfAttributesToFormControl(this.cognitoUserNewPassword.challengeParam.requiredAttributes, this.newPasswordFormGroup);
    this.cognitoService.completeNewPassword(this.cognitoUserNewPassword, this.newPasswordFormGroup.get('password').value, attributes).then((success) => {
      this.messageCustomService.successMessage(this.translateService.instant('MESSAGES.SUCCESS.UPDATE_DATA'));
      this.showView('showLogin');
    }
    ).catch((error) => {
      this.messageCustomService.errorMessage(this.translateService.instant('MESSAGES.ERROR.NOT_UPDATE_DATA'));
    });

  }

  async login() {
    const usuario = this.loginFormGroup.get("userName").value;
    const password = this.loginFormGroup.get("password").value;

    this.cognitoService.signIn(usuario, password).then(async (success) => {

      const cognitoUser = success
      if (cognitoUser.challengeName && cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.cognitoUserNewPassword = cognitoUser;
        await this.createFormNewPassword();
      } else {
        this.checkUserIsManage(cognitoUser)
      }

    }).catch((e) => {
      this.messageCustomService.errorMessage(this.translateService.instant("MESSAGES.ERROR.LOG_IN"))
    });
  }

  checkUserIsManage(cognitoUser) {
    this.cognitoService.checkUserIsManage(cognitoUser.username, AWS_AUTH.Auth.userPoolId).subscribe((success) => {
      if (success.result) {
        this.authService.authenticate(cognitoUser);
        this.router.navigate(['/pool']);
      } else {
        this.messageCustomService.errorMessage(this.translateService.instant("MESSAGES.ERROR.USER_NOT_MANAGE"))
      }
    })
  }

  createFormLogin() {
    this.loginFormGroup = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(MIN_LENGTH_PASSWORD)]],
        userName: ['', Validators.required]
      }
    )
  }

  async createFormNewPassword() {
    try {
      const schemaAttributes = await this.cognitoService.getDescribePool(AWS_AUTH.Auth.userPoolId).toPromise();
      this.passwordPolicies = schemaAttributes.UserPool.Policies.PasswordPolicy;

      const validator = prepareValidatorWithPasswordPolicies(this.passwordPolicies);

      this.newPasswordFormGroup = this.formBuilder.group(
        {
          password: ['', validator],
          confirmPassword: ['', Validators.required]
        }, {
        validators: MustMatch('password', 'confirmPassword')
      }
      )

      this.cognitoUserNewPassword.challengeParam.requiredAttributes.forEach(attribute => {
        this.newPasswordFormGroup.addControl(attribute, new FormControl('', Validators.required))
      });

      this.showView('showNewPasswordRequired');

    } catch (e) {
      this.messageCustomService.errorMessage(e)
    }
  }

  showView(view: string) {
    this.loginFormGroup.reset()
    this.newPasswordFormGroup.reset();

    switch (view) {
      case "showNewPasswordRequired":
        this.showNewPasswordRequired = true;
        this.showLogin = false;
        this.showForgotPassword = false;
        break;
      case "showLogin":
        this.showNewPasswordRequired = false;
        this.showLogin = true;
        this.showForgotPassword = false;
        break;
      case "showForgotPassword":
        this.showNewPasswordRequired = false;
        this.showLogin = false;
        this.showForgotPassword = true;
        break;
      default:
        break;
    }
  }

}
