/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

export const AWS_AUTH = {
  Auth: {
    // Amazon Cognito Region
    region: "eu-west-1",

    // Amazon Cognito User Pool ID (MV_Saconia pool)
    userPoolId: "eu-west-1_gQd1hL4tI",

    // Amazon Cognito Web Client ID (MV_Saconia_API_Client)
    userPoolWebClientId: "3gaf3st0g9g496t6a1p968sqe7",

  },
};