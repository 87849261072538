<app-location-bar titulo="{{'TITLE_BAR.LIST_USER_GROUP' | translate }}" [showBackButton]="false"></app-location-bar>

<div *ngIf="showNotAvailablePoolslMessage(); else showAvailablePools">
    <p-messages severity="error">
        <ng-template pTemplate>
            <div class="p-ml-2">{{'MESSAGES.ERROR.NOT_ALLOWED_POOLS' | translate}}</div>
        </ng-template>
    </p-messages>
</div>

<ng-template #showAvailablePools>
    <div class="container-fluid p-mt-3 card-columns">
        <div (click)="selectPool(pool)" *ngFor="let pool of pools;" class="card bg-light" style="cursor: pointer;">
            <div class="card-body">
                <p class="card-text">{{pool.poolName}}</p>
            </div>
        </div>
    </div>
</ng-template>