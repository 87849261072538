import { FormControl, FormGroup, Validators } from '@angular/forms';
const generator = require('generate-password-browser');

export function windowHistoryBack() {
    window.history.back();
}

export function setSessionStorage(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
}

export function getSessionStorage(key: string) {
    const valueSessionStorage = JSON.parse(sessionStorage.getItem(key));
    if (valueSessionStorage !== null) {
        return valueSessionStorage;
    } else {
        return '';
    }
}

export function removeSessionStorage(key: string) {
    sessionStorage.removeItem(key)
}

export function removeInitialAndLastSpacingAndQuotesAndSplit(string: string, splitValue: string): string[] {
    if (string !== "") {
        const splitString = string.trim().replace(/(^\"|\"$)/mg, '').split(splitValue);
        return splitString.filter(function (el) {
            //Filtro que quita espacios delante y detras y sino se ha quedado en "" no lo devuelve.
            return el.trim() !== "";
        })
    }
    //Solucion sonar, habría que probar si funciona todo correctamente.
    //return [];
}

export function iterateJsonAndAddKeyValueToArray(object, array) {
    const excludedKeys = ['username', 'password', 'confirmPassword', 'temporaryPassword']
    let nameKey: any;
    for (nameKey in object) {
        const includeKey = excludedKeys.includes(nameKey)
        if (!includeKey) {
            const value = object[nameKey];
            const valueString = typeof value === 'number' ? value.toString() : value;
            const attribute = { Name: nameKey, Value: valueString }
            array.push(attribute);
        }
    }
}

export function addCustomFieldsToFormControl(customFields: any, form: FormGroup) {
    customFields.forEach(field => {
        let validators = [];
        validators.push(Validators.required);
        if (field.Name === "email") {
            validators.push(Validators.email);
        }
        form.addControl(field.Name, new FormControl('', validators));
    });
}

export function getObjectOfAttributesToFormControl(arrayToIterate: string[], form: FormGroup) {
    let object = {}
    arrayToIterate.forEach(nameAttribute => {
        object[nameAttribute] = form.controls[nameAttribute].value
    });
    return object;
}

export function prepareValidatorWithPasswordPolicies(passwordPolicies: any) {
    let validator = [];

    validator.push(Validators.required);
    validator.push(Validators.minLength(passwordPolicies.MinimumLength));

    if (passwordPolicies.RequireUppercase) validator.push(Validators.pattern(".*[A-Z].*"))
    if (passwordPolicies.RequireLowercase) validator.push(Validators.pattern(".*[a-z].*"))
    if (passwordPolicies.RequireNumbers) validator.push(Validators.pattern(".*[0-9].*"),)
    if (passwordPolicies.RequireSymbols) validator.push(Validators.pattern(".*[$&+,:;=\\\\?¿@#|/'<>.^*()%!-].*"))

    return validator;
}

export function containsAttribute(attributeName: string, attributes: any[]) {
    let contains = false;
    for (var i = 0; i < attributes.length && !contains; i++) {
        const attribute = attributes[i];
        if (attribute['Name'] === attributeName) {
            contains = true;
        }
    }
    return contains;
}


export function generatePasswordWithPolicies(passwordPolicies) {
    const password = generator.generate({
      length: passwordPolicies.MinimumLength,
      numbers: passwordPolicies.RequireNumbers,
      uppercase: passwordPolicies.RequireUppercase,
      symbols: passwordPolicies.RequireSymbols,
      strict: passwordPolicies.RequireSymbols
    });

    return password;
}