import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { TranslateModule } from '@ngx-translate/core';
import { PanelModule } from 'primeng/panel';
import { MoviredModule } from 'src/app/components/movired.module';
import { DropdownModule } from 'primeng/dropdown';
import { PoolsListComponent } from './pools-list/pools-list.component';
import { MessagesModule } from 'primeng/messages';


@NgModule({
  declarations: [PoolsListComponent],
  imports: [
    CommonModule,
    DropdownModule,
    TranslateModule.forChild(),
    CardModule,
    PanelModule,
    MoviredModule,
    MessagesModule
  ]
})
export class PoolsModule { }
