import { ChangeDetectorRef, Component } from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent {

  show: boolean;
  constructor(private loaderService: SpinnerService, private _changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.loaderService.isLoading.subscribe(loading => {
      this.show = loading;
      this._changeDetectorRef.detectChanges();
    });

  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.loaderService.isLoading.unsubscribe();
  }

}
