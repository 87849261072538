import { CognitoAttributes } from '../cognito-attributes/cognito-attributes.model';

export class CognitoUser {
    Username?: string;
    Attributes?: Array<CognitoAttributes>;
    UserCreateDate?: Date;
    UserLastModifiedDate?: Date;
    Enabled?: boolean;
    UserStatus?: string;
    GroupName?: string[];
}
