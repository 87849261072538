import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { setSessionStorage } from 'src/app/functions/generics.js';
import { CognitoAttributes } from 'src/app/models/cognito-attributes/cognito-attributes.model';
import { CognitoUser } from 'src/app/models/cognito-user/cognito-user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CognitoService } from 'src/app/services/cognito/cognito.service';
import { PIPE_DATE_YEAR_MONTH_DAY_HOURS_MINS_SECONDS } from '../../../utils/const/const';
import { PIPE_DATE_YEAR_MONTH_DAY } from '../../../utils/const/const';


@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit {

  colsTableUser: any[];
  cognitoUserList: Array<CognitoUser>;
  formatDateWithHours = PIPE_DATE_YEAR_MONTH_DAY_HOURS_MINS_SECONDS;
  formatDate = PIPE_DATE_YEAR_MONTH_DAY;
  detailsUser: any
  @ViewChild('userTable')
  userTable: Table;
  poolId: string;

  constructor(public translateService: TranslateService, private cognitoService: CognitoService,
    private router: Router, public authService: AuthService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.poolId = this.activatedRoute.snapshot.paramMap.get('poolId');
    this.prepareModels();
    this.prepareColsTableUser();
    this.getUserList();
  }

  prepareColsTableUser() {
    this.colsTableUser = [
      { field: 'Username', header: 'GENERICS.USERNAME' },
      { field: 'Email', subFieldName: 'Name', subFieldValue: 'Value', header: 'GENERICS.EMAIL',   },
      { field: 'GroupName', header: 'GENERICS.GROUPS' },
      { field: 'Enabled', header: 'GENERICS.ACTIVE' },
      { field: 'UserStatus', header: 'GENERICS.USER_STATUS' },
      // { field: 'UserCreateDate', header: 'GENERICS.CREATE_DATE' },
      { field: 'UserLastModifiedDate', header: 'GENERICS.LAST_MODIFIED_DATE' },
      { field: 'Operations', header: 'GENERICS.OPERATIONS' }
    ];
  }

  prepareModels() {
    this.cognitoUserList = new Array<CognitoUser>();
  }

  getUserList() {
    this.cognitoService.getUserList(this.poolId).subscribe((success) => {
      this.cognitoUserList = success.Users;
      if (success && success.Users && success.Users.length > 0){
        //Añadimos Email para que sea mas facil de acceder
        this.cognitoUserList = success.Users.map(userCognito => {
          const email = userCognito.Attributes.find((attribute => {
            return attribute.Name === 'email';
          }))
          return {...userCognito, Email: email.Value };
        })
      }

    })
  }

  goToDetailsUser(usuario: CognitoUser) {
    setSessionStorage("detailsUser", usuario);
    this.router.navigate([usuario.Username], { relativeTo: this.activatedRoute });
  }

  goToCreateNewUser() {
    setSessionStorage("editView", false);
    this.router.navigate(['..', 'new-user'], { relativeTo: this.activatedRoute.parent });
  }

  goToEditUser(usuario: CognitoUser) {
    setSessionStorage("editUser", usuario);
    setSessionStorage("editView", true);
    this.router.navigate(['..', 'new-user'], { relativeTo: this.activatedRoute.parent });
  }

  addNewUsersImportedCsv(user) {
    let newCognitoUser = new CognitoUser();
    newCognitoUser.Username = user.Username;
    newCognitoUser.GroupName = user.roles;
    newCognitoUser.Enabled = true;
    newCognitoUser.UserStatus = "UNCONFIRMED";
    newCognitoUser.Attributes = user.UserAttributes;

    this.cognitoUserList.push(newCognitoUser);
    this.userTable._totalRecords = this.cognitoUserList.length
  }
}
