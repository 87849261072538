import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { getSessionStorage } from 'src/app/functions/generics';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SESSION_POOLS_NAME } from 'src/app/utils/const/const';

@Injectable({
  providedIn: 'root'
})
export class PoolGuard implements CanActivate {
  constructor(private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const allowePools = getSessionStorage(SESSION_POOLS_NAME);
    let allowAccess: boolean = false;

    const desiredPool = next.params.poolId;

    for (let i = 0; i < allowePools.length && !allowAccess; i++) {
      let pool = allowePools[i];
      if (pool.poolId === desiredPool) {
        allowAccess = true;
      }
    }
    if (allowAccess) {
      return true;
    }
    else {
      this.router.navigate(['/forbidden']);
      return false;
    }
  }
}