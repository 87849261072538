<app-location-bar titulo="{{this.authService.getSelectedPool()}} - {{'TITLE_BAR.DETAILS_USER' | translate: {username:cognitoUser.Username} }}" [showBackButton]="true"></app-location-bar>

<div class="container p-mt-3">
    <div class="col align-self-center p-grid">
        <ng-container *ngFor="let attributes of cognitoUser.Attributes">
            <div *ngIf="attributes.Name == 'name'" class="p-col-12 p-md-6 p-lg-3">
                <h5>{{'GENERICS.NAME' |translate }}:</h5> 
                <span>{{attributes.Value}}</span>
            </div>
            <div *ngIf="attributes.Name == 'family_name'" class="p-col-12 p-md-6 p-lg-3">
                <h5>{{'GENERICS.SURNAME' |translate }}:</h5> 
                <span>{{attributes.Value}}</span>
            </div>
            <div *ngIf="attributes.Name == 'email'" class="p-col-12 p-md-6 p-lg-3">
                <h5>{{'GENERICS.EMAIL' |translate }}:</h5> 
                <span>{{attributes.Value}}</span>
            </div>
        </ng-container>
        <div *ngIf="cognitoUser.GroupName" class="p-col-12 p-md-6 p-lg-3">
            <h5>{{'GENERICS.GROUPS' |translate }}:</h5>
            <ul  class="list-group list-group-flush">
                <li style="list-style: none;"  class="badge badge-primary p-mt-2" 
                    *ngFor="let groupName of cognitoUser.GroupName">
                    {{groupName}}
                </li>
            </ul>
        </div>
        <div class="p-col-12 p-md-6 p-lg-3">
            <h5>{{'GENERICS.USER_STATUS' |translate }}:</h5> <span>{{'COGNITO_USER_STATUS.'+cognitoUser.UserStatus | translate}}</span>
        </div>
        <div class="p-col-12 p-md-6 p-lg-3">
            <h5>{{'GENERICS.ACTIVE' |translate }}:</h5> 
            <span [ngClass]="{'text-danger':!cognitoUser.Enabled,'text-success': cognitoUser.Enabled}">
                {{''+cognitoUser.Enabled | translate}}
            </span>

        </div>
        <div class="p-col-12 p-md-6 p-lg-3">
            <h5>{{'GENERICS.CREATE_DATE' |translate }}:</h5> <span>{{cognitoUser.UserCreateDate | date: formatDate}}</span>

        </div>

        <div class="p-col-12 p-md-6 p-lg-3">
            <h5>{{'GENERICS.LAST_MODIFIED_DATE' |translate }}:</h5> <span>{{cognitoUser.UserLastModifiedDate | date: formatDateWithHours}}</span>
        </div>
        <div class="p-col-12 p-md-6 p-lg-6">
            <h5>{{'GENERICS.ATTRIBUTES' |translate }}:</h5>
                <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">{{'GENERICS.NAME' |translate }}:</th>
                        <th scope="col">{{'GENERICS.VALUE' |translate }}:</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let attributes of attributesCustom">
                            <td>{{attributes.Name}}:</td>
                            <td>{{attributes.Value}}</td>
                        </tr>
                    </tbody>
                  </table>
        </div>

        
    </div>
</div>